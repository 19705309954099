import Vue from "vue";
import Vuex from "vuex";
// import axios from "axios";

Vue.use(Vuex);

// LOGIN 정보 보관
export default new Vuex.Store({
  // 컴포넌트 간 공유될 DATA
  state: {
    accessToken: null,
    refreshToken: null,
    memberId: null,
    username: null,
    nickname: null,
    grade: null,
    gradeValue: null,
  },
  getters: {},
  // state 값을 변경하는 로직 (setter 와 유사한 개념)
  mutations: {

    saveSignInInfo: function (state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.memberId = payload.memberId;
      state.username = payload.username;
      state.nickname = payload.nickname;
      state.grade = payload.grade;
      state.gradeValue = payload.gradeValue;
      state.role = payload.role;

      state.status = payload.status;
      state.statusValue = payload.statusValue;
      // 토큰을 로컬 스토리지에 저장
      localStorage.accessToken = payload.accessToken;
      localStorage.refreshToken = payload.refreshToken;
      localStorage.memberId = payload.memberId;
      localStorage.username = payload.username;
      localStorage.nickname = payload.nickname;
      localStorage.grade = payload.grade;
      localStorage.gradeValue = payload.gradeValue;
      localStorage.status = payload.status;
      localStorage.statusValue = payload.statusValue;
      localStorage.role = payload.role;
    },
    updateToken: function (state,payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;

      localStorage.accessToken = payload.accessToken;
      localStorage.refreshToken = payload.refreshToken;
    },
    updateMyInfo: async function (state,payload) {
      state.memberId = payload.memberId;
      state.username = payload.username;
      state.nickname = payload.nickname;
      state.grade = payload.grade;
      state.gradeValue = payload.gradeValue;
      state.status = payload.status;
      state.statusValue = payload.statusValue;
      state.role = payload.role;

      localStorage.memberId = payload.memberId;
      localStorage.username = payload.username;
      localStorage.nickname = payload.nickname;
      localStorage.grade = payload.grade;
      localStorage.gradeValue = payload.gradeValue;
      localStorage.status = payload.status;
      localStorage.statusValue = payload.statusValue;
      localStorage.role = payload.role;
    },
    // LOGIN(state, {accessToken}) {
    //   state.accessToken = accessToken;
    //
    //   // 토큰을 로컬 스토리지에 저장
    //   localStorage.accessToken = accessToken;
    // },
    updateSiteInfo: function (state,payload) {
      state.constructionText = payload.constructionText;
      state.showMemberLevel = payload.showMemberLevel;
      state.siteName = payload.siteName;
      state.siteCsUrl = payload.siteCsUrl;
      state.useCasino = payload.useCasino;
      state.useCharge = payload.useCharge;
      state.useEventAttendance = payload.useEventAttendance;
      state.useExchange = payload.useExchange;
      state.useInplay = payload.useInplay;
      state.useLive = payload.useLive;
      state.usePowerball5m = payload.usePowerball5m;
      state.usePowerball3m = payload.usePowerball3m;
      state.useSpeedkeno = payload.useSpeedkeno;
      state.useSports = payload.useSports;
      state.useTokenGame = payload.useTokenGame;
      state.useVbasketball = payload.useVbasketball;
      state.useVdogracing = payload.useVdogracing;
      state.useVsoccer = payload.useVsoccer;
      state.useLoginCaptcha = payload.useLoginCaptcha;

      localStorage.constructionText = payload.constructionText;
      localStorage.showMemberLevel = payload.showMemberLevel;
      localStorage.siteName = payload.siteName;
      localStorage.siteCsUrl = payload.siteCsUrl;
      localStorage.useCasino = payload.useCasino;
      localStorage.useCharge = payload.useCharge;
      localStorage.useEventAttendance = payload.useEventAttendance;
      localStorage.useExchange = payload.useExchange;
      localStorage.useInplay = payload.useInplay;
      localStorage.useLive = payload.useLive;
      localStorage.usePowerball5m = payload.usePowerball3m;
      localStorage.usePowerball3m = payload.usePowerball3m;
      localStorage.useSpeedkeno = payload.useSpeedkeno;
      localStorage.useSports = payload.useSports;
      localStorage.useTokenGame = payload.useTokenGame;
      localStorage.useVbasketball = payload.useVbasketball;
      localStorage.useVdogracing = payload.useVdogracing;
      localStorage.useVsoccer = payload.useVsoccer;
      localStorage.useLoginCaptcha = payload.useLoginCaptcha;
    },
    LOGOUT(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.memberId = null;
      state.username = null;
      state.nickname = null;
      state.grade = null;
      state.gradeValue = null;
      state.status = null;
      state.statusValue = null;

      state.constructionText = null;
      state.showMemberLevel = null;
      state.siteName = null;
      state.siteCsUrl = null;
      state.useCasino = null;
      state.useCharge = null;
      state.useEventAttendance = null;
      state.useExchange = null;
      state.useInplay = null;
      state.useLive = null;
      state.usePowerball5m = null;
      state.usePowerball3m = null;
      state.useSpeedkeno = null;
      state.useSports = null;
      state.useTokenGame = null;
      state.useVbasketball = null;
      state.useVdogracing = null;
      state.useVsoccer = null;
      state.useLoginCaptcha = null;


      delete localStorage.accessToken;
      delete localStorage.refreshToken;
      delete localStorage.memberId;
      delete localStorage.username;
      delete localStorage.nickname;
      delete localStorage.grade;
      delete localStorage.gradeValue;
      delete localStorage.statusValue;

      delete localStorage.constructionText;
      delete localStorage.showMemberLevel;
      delete localStorage.siteName;
      delete localStorage.siteCsUrl;
      delete localStorage.useCasino;
      delete localStorage.useCharge;
      delete localStorage.useEventAttendance;
      delete localStorage.useExchange;
      delete localStorage.useInplay;
      delete localStorage.useLive;
      delete localStorage.usePowerball5m;
      delete localStorage.usePowerball3m;
      delete localStorage.useSpeedkeno;
      delete localStorage.useSports;
      delete localStorage.useTokenGame;
      delete localStorage.useVbasketball;
      delete localStorage.useVdogracing;
      delete localStorage.useVsoccer;
      delete localStorage.useLoginCaptcha;

    }
  },
  // 사용자의 입력에 따라 반응할 METHODS
  actions: {
    LOGIN: async function ({commit}, {username, password}) {
      return await axios
          .post("/app/auth/signin", {
            username,
            password,
            role: "ROLE_MEMBER",
            domain : location.host
          })
          .then(response => {
            commit("saveSignInInfo", {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              memberId: response.data.memberId,
              username: response.data.username,
              nickname: response.data.nickname,
              grade: response.data.grade,
              gradeValue: response.data.gradeValue,
              status: response.data.status,
              role: response.data.role,
            });

            // 성공적으로 로그인이 되었을 경우
            if (response.status === 200) {
              // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
              axios.defaults.headers.common["Authorization"] = `Bearer ${
                  response.data.accessToken
              }`;
            } else {
              console.log(JSON.stringify(response.data));
              // alert("로그인 실패");
            }
          });
      // .catch(ex => {
      //   console.warn("ERROR!!!!! : ", ex);
      //   alert("로그인 실패");
      // });
      // return axios.post(`${resourceHost}/login`, {email, password})
      //   .then(({data}) => {
      //     commit("LOGIN", data);
      //     // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
      //     axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
      //   });
    },
    REFRESHTOKEN: async function ({commit}) {
      // axios.defaults.headers.common["Authorization"] = undefined;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async function (resolve, reject) {
        await axios
            .post("/app/auth/refreshToken", {
              memberId: localStorage.memberId,
              refreshToken: localStorage.refreshToken
            })
            .then(response => {

              commit("updateToken", {
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,

              });

              // 성공적으로 로그인이 되었을 경우
              if (response.status === 200) {
                // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                axios.defaults.headers.common["Authorization"] = `Bearer ${
                    response.data.accessToken
                }`;
              } else {
                console.log("refreshToken 로그인 실패" , response);
                // alert("로그인 실패");
              }
              resolve(response)
            }).catch(async ex => {
              console.log("refresh ex 로그인 실패")
              console.log(ex)
              reject(ex)
            });
      });


    },
    UPDATEMYINFO: async function ({commit}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async function (resolve, reject) {
        await axios
            .get("/app/member", {})
            .then(response => {

              // 성공적으로 로그인이 되었을 경우
              if (response.status === 200) {
                // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                commit("updateMyInfo", {
                  memberId: response.data.memberId,
                  username: response.data.username,
                  nickname: response.data.nickname,
                  grade: response.data.grade,
                  gradeValue: response.data.gradeValue,
                  status: response.data.status,
                  statusValue: response.data.statusValue,
                  role: response.data.role,
                });

              } else {
                // console.log(JSON.stringify(response.data));
                // //정상 코드가 아닐시 로그아웃 처리
                // this.$store
                //     .dispatch("LOGOUT")
                //     .then(() => {
                //       this.$router.push({name: "login"})
                //     })
                //     .catch(ex => {
                //       console.log("global logout")
                //       console.log(ex)
                //     });
                // // alert("로그인 실패");

              }
              resolve(response)
            }).catch(async ex => {
              console.log("updateInfo 실패")
              console.log(ex)
              reject(ex)
              // if (ex.response.status === 401) {
              //   if (await this.$refreshToken()) {
              //     console.log('aa')
              //   } else {
              //     console.log('bb')
              //     this.$logout();
              //   }
              // } else if (ex.response.status === 403) {
              //   this.$logout();
              // }
            });
      });



    },
    UPDATESITEINFO: async function ({commit}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async function (resolve, reject) {
        await axios
            .get("/app/common/appInfo", {})
            .then(response => {

              // 성공적으로 로그인이 되었을 경우
              if (response.status === 200) {
                // 모든 HTTP 요청 헤더에 Authorization 을 추가한다.
                commit("updateSiteInfo", {
                  constructionText : response.data.constructionText,
                  showMemberLevel : response.data.showMemberLevel,
                  siteName : response.data.siteName,
                  siteCsUrl : response.data.siteCsUrl,
                  useCasino : response.data.useCasino,
                  useCharge : response.data.useCharge,
                  useEventAttendance : response.data.useEventAttendance,
                  useExchange : response.data.useExchange,
                  useInplay : response.data.useInplay,
                  useLive : response.data.useLive,
                  usePowerball5m : response.data.usePowerball5m,
                  usePowerball3m : response.data.usePowerball3m,
                  useSpeedkeno: response.data.useSpeedkeno,
                  useSports : response.data.useSports,
                  useTokenGame : response.data.useTokenGame,
                  useVbasketball : response.data.useVbasketball,
                  useVdogracing : response.data.useVdogracing,
                  useVsoccer : response.data.useVsoccer,
                  useLoginCaptcha : response.data.useLoginCaptcha,

                });
              } else {

              }
              resolve(response)
            }).catch(async ex => {
              console.log("updateInfo 실패")
              console.log(ex)
              reject(ex)
            });
      });
    },
    LOGOUT: function ({commit}) {

      axios.defaults.headers.common["Authorization"] = undefined;
      commit("LOGOUT");
    }
    // ,
    // ME: function(context) {
    //   axios.get("/api/v1/members/me")
    //     .then({ data } => {
    //     })
    //     .catch(ex => {
    //       console.warn("ERROR!!!!! : ", ex);
    //     });
    // }
  }
});

const enhanceAccessToken = () => {
  const {accessToken} = localStorage;
  if (!accessToken) {
    return;
  }
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};
enhanceAccessToken();
