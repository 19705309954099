<template>
  <v-app
      id="app"
      :style="this.$route.path === '/login' || this.$route.path === '/join' ? 'background:unset':''"
  >
    <template v-if="dialog.underMaintenance">
      <under-maintenance-dialog
          :dialog.sync=dialog.underMaintenance
          :text=dialog.underMaintenanceText
      ></under-maintenance-dialog>
    </template>
    <template v-else>
      <router-view name="header"></router-view>
      <v-main>
        <router-view></router-view>
      </v-main>
    </template>
    <alert-dialog
        :dialog.sync=dialog.alert
        :text=dialog.alertText
        :option="dialog.alertOption"
        v-on:alertAction="alertAction"
    ></alert-dialog>
    <v-snackbar
        v-model="snackbar"
        vertical
        centered
    >
      <div v-html="dialog.alertText">
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!--    <router-view name="footer"></router-view>-->
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import '@/styles/bg.css'
import '@/styles/default.css'
import {eventBus} from "@/plugins/eventBus";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

export default {
  name: 'App',
  components: {
    // HelloWorld,
  },

  data: () => ({
    snackbar: false,
    result: false,
    dialog: {
      write: false,
      writeData: {},
      alert: false,
      alertText: '',
      alertOption: {
        persistent: false
      },
      alertActionType: '',
      snackBar: false,
      snackBarText: '',
      confirm: false,
      confirmData: {
        title: "",
        content: "",
        leftBtn: "",
        rightBtn: "",
        data: {}
      },
      underMaintenance: false,
      underMaintenanceText: '',
    },
    socket: null,
    stompClient: null,
    connectedFlag: false,
    subscriptionIdSet: {
      alert: '',
      sports: '',
      inplay: '',
      live: ''
    },
    socketInterval: null,
  }),
  methods: {
    showAlert: function (text, option, action) {
      console.log(text, option, action)
      if (action === 'logout') {
        if (text !== '' && text !== undefined) {
          this.dialog.alertText = text
        } else {
          this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
        }

        this.snackbar = true
        this.$logout()
      } else {
        if (text !== undefined && text !== '') {
          this.dialog.alert = true;
          this.dialog.alertText = text
          this.dialog.alertOption = option
          this.dialog.alertActionType = action
        }
      }

    },
    alertAction: function () {
      console.log(this.dialog)
      if (this.dialog.alertOption.data !== undefined && Object.keys(this.dialog.alertOption.data).length>0) {
          this.$goLink('myBet',this.dialog.alertOption.data.params);
      } else {
        this.$alertAction(this.dialog.alertActionType);
        eventBus.initAlert()
      }
      this.dialog.alert = false;
      this.dialog.alertText = ''
      this.dialog.alertOption = {
        persistent: false
      }
      this.dialog.alertActionType = ''


    },
    setMyInfo: async function () {
      console.log('myInfo')
      let result = {flag: false}
      if (this.$route.path !== '/login' && !this.$route.path.includes('/join')) {
        result = await this.$updateMyInfo()

        if (result.flag) {
          console.log('myInfo')
          eventBus.getData()
          eventBus.updateTopBarData()
        } else {
          if (result.status === 403) {
            this.dialog.alert = true;
            this.dialog.alertText = result.msg
            this.dialog.alertActionType = 'logout'
            this.dialog.alertOption.persistent = true
          } else {
            this.dialog.alert = true;
            this.dialog.alertText = '잠시 후 다시 시도해주세요.'
            this.dialog.alertActionType = ''
            this.dialog.alertOption.persistent = true
          }
        }
      }
      console.log('myInfo',result)

    },
    getSiteInfo: async function () {
      // console.log('topbar',this.$tokenFlag)
      this.result = false
      let result = await this.$updateSiteInfo()
      this.result = result.flag
      if (result.flag) {

        if (result.data.underMaintenance) {
          this.dialog.underMaintenance = true
          console.log(result.data.constructionText)
          if (result.data.constructionText.length > 0) {
            this.dialog.underMaintenanceText = result.data.constructionText
          } else {
            this.dialog.underMaintenanceText = '사이트 점검 중이므로 이용하실 수 없습니다.';
          }
        }
      } else {
        if (result.status === 403) {
          this.dialog.alertActionType = 'logout'
          this.dialog.alertOption.persistent = true
        }
        this.dialog.alert = true;
        this.dialog.alertText = result.msg
      }

      console.log('localStorage', localStorage)
      console.log('localStorage', JSON.parse(localStorage.useCasino))
    },
    setSubscribe: function (type) {

      let subscriptionResult = '';
      if (this.subscriptionIdSet[type] === '') {
        if (type === 'alert') {
          subscriptionResult = this.stompClient.subscribe(`/queue/${localStorage.memberId}/alert`, async tick => {
            console.log('test1111',tick.body)
            eventBus.showAlarm(JSON.parse(tick.body))
            return tick.headers.subscription
          });
        }
        if (type === 'sports') {
          subscriptionResult = this.stompClient.subscribe(`/topic/sportsGames`, async tick => {
            eventBus.setGameDataUsingSocket(JSON.parse(tick.body))
            return tick.headers.subscription
          });
        }
        if (type === 'inplay') {
          subscriptionResult = this.stompClient.subscribe(`/topic/inplayGames`, async tick => {
            eventBus.setGameDataUsingSocket(JSON.parse(tick.body))
            return tick.headers.subscription
          });
        }
        if (type === 'live') {
          subscriptionResult = this.stompClient.subscribe(`/topic/liveGames`, async tick => {
            eventBus.setGameDataUsingSocket(JSON.parse(tick.body))
            return tick.headers.subscription
          });
        }


        this.subscriptionIdSet[type] = subscriptionResult.id
      }

    },
    async connect() {

      this.socket = new SockJS(process.env.VUE_APP_WS_URL);
      this.stompClient = Stomp.over(this.socket);
      // this.stompClient = this.$initSocket()
      await this.stompClient.connect(
          {},
          frame => {
            console.log('main 연결')
            this.connectedFlag = true;
          },
          error => {
            console.log(error);
            this.connectedFlag = false;
          }
      );



    },
    disconnect() {

      if (this.subscriptionIdSet.alert !== '') {
        this.stompClient.unsubscribe(this.subscriptionIdSet.alert)
        this.subscriptionIdSet.alert = ''
      }
      if (this.subscriptionIdSet.sports !== '') {
        this.stompClient.unsubscribe(this.subscriptionIdSet.sports)
        this.subscriptionIdSet.sports = ''
      }
      if (this.subscriptionIdSet.inplay !== '') {
        this.stompClient.unsubscribe(this.subscriptionIdSet.inplay)
        this.subscriptionIdSet.inplay = ''
      }
      if (this.subscriptionIdSet.live !== '') {
        this.stompClient.unsubscribe(this.subscriptionIdSet.live)
        this.subscriptionIdSet.live = ''
      }
      console.log(this.stompClient, this.socket)
      if (this.stompClient !== null) {
        this.stompClient.disconnect()
      }
      if (this.socket !== null) {
        this.socket.close()
      }
      this.connectedFlag = false;
      clearInterval(this.socketInterval)
      this.socketInterval = null
    },
    tickleConnection() {
      this.connectedFlag ? this.disconnect() : this.connect();
    },
    socketCheck: function () {
      if (!this.stompClient.connected) {
        this.connectedFlag = false;
        this.connect();
      }
    },
    socketSubscribe : function () {
      if (this.subscriptionIdSet.alert === '') {
        this.setSubscribe('alert')
        console.log('1connected alert')
      }

      if (this.$route.path.includes('/game/sports')) {
        if (this.subscriptionIdSet.sports === '') {
          this.setSubscribe('sports')
          console.log('1connected sports')
        }
      } else {
        if (this.subscriptionIdSet.sports !== '') {
          this.stompClient.unsubscribe(this.subscriptionIdSet.sports)
          this.subscriptionIdSet.sports = ''
          console.log('1connected sports2')
        }
      }

      if (this.$route.path.includes('/game/INPLAY')) {
        if (this.subscriptionIdSet.inplay === '') {
          this.setSubscribe('inplay')
          console.log('1connected inplay')
        }
      } else {
        if (this.subscriptionIdSet.inplay !== '') {
          this.stompClient.unsubscribe(this.subscriptionIdSet.inplay)
          this.subscriptionIdSet.inplay = ''
          console.log('1connected inplay2')
        }
      }

      if (this.$route.path.includes('/game/LIVE')) {
        if (this.subscriptionIdSet.live === '') {
          this.setSubscribe('live')
          console.log('1connected live')
        }
      } else {
        if (this.subscriptionIdSet.live !== '') {
          this.stompClient.unsubscribe(this.subscriptionIdSet.live)
          this.subscriptionIdSet.live = ''
          console.log('1connected live2')
        }
      }
    }
  },
  mounted() {
    // this.setMyInfo()
    // this.getSiteInfo()
  },
  async beforeRouteLeave(to, from, next) {
    next();
  },
  created() {
    eventBus.$on('getSiteInfo', async () => {
      await this.getSiteInfo()
    })

    eventBus.$on('showAlert', (text, option, action) => {
      console.log('showAlert',text, option, action)
      this.showAlert(text, option, action)
    })


  },
  beforeDestroy() {
    this.disconnect()
  },
  watch: {
    connectedFlag: {
      handler: function (val, oldVal) {
        console.log('connectedFlag?',val,oldVal)
        if (val) {
          this.socketSubscribe()
        }
      },
      deep: true
    },
    async $route(to, from) {
      console.log('to,from', to.path, from)

      await this.getSiteInfo()

      if (to.name === 'login' || to.name.includes('/join')) {
        await this.disconnect()
      } else {
        await this.setMyInfo()
        if (!this.connectedFlag) {
          await this.connect();
        } else {
          this.socketSubscribe()
        }
        if (this.socketInterval === null) {
          this.socketInterval = setInterval(this.socketCheck.bind(this), 3000);
        }
      }
    },
  }
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(var(--vh, 1vh) * 100);
  //overflow: auto;
  font-size: 11px;
}
.v-application--wrap {
  min-height: calc(var(--vh, 1vh) * 100) !important;
}




</style>
